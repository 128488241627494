import { graphql, navigate } from "gatsby"
import React, { FC, useEffect } from "react"
import { Props } from "./types"
import * as styles from "../styles.module.scss"
import SEO from "src/components/SEO"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import Logo from "src/components/Icons/Logo"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
  location,
}) => {
  const { getPage, currentPath } = usePages()

  const from = location.state?.from

  useEffect(() => {
    if (!from) {
      navigate("/newsletter")
    }
  }, [from])

  if (!from) {
    return null
  }

  const [, subtitle] = texts.newsletterSuccessSubtitle.find(
    ([id]: [string, string]) => id === from,
  )

  return (
    <>
      <SEO
        title={texts.metaTitle}
        description={texts.metaDescription}
        openGraph={{
          title: texts.metaTitle,
          description: texts.metaDescription,
          url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        }}
      />
      <main className={styles.main}>
        <Link to={getPage("index").url}>
          <Logo className={styles.logo} />
        </Link>
        <div className={`${styles.container} ${styles.containerSuccess}`}>
          <h1 className={styles.title}>{texts.verifyTitle}</h1>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <Link
            to={getPage("blog").url}
            className={`button button-second ${styles.button}`}
          >
            {texts.button}
          </Link>
          <ArrowLink
            text={texts.link}
            to={getPage("index").url}
            className={styles.link}
          />
        </div>

        <div className={styles.legalText}>
          Flow © 2020 <span className={styles.dot}> • </span>
          <Link
            to={getPage("legal/terms-of-service").url}
            className={styles.linkLegal}
          >
            {texts.Terms}
          </Link>
          <span> {texts.and} </span>
          <Link
            to={getPage("legal/privacy-policy").url}
            className={styles.linkLegal}
          >
            {texts.Privacy}
          </Link>
        </div>
      </main>
    </>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        verifyTitle
        newsletterSuccessSubtitle
        button
        link
        Terms
        and
        Privacy
      }
    }
  }
`

export default Template
